/* eslint-disable prettier/prettier */
import React, { createContext, useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { jwtDecode } from 'jwt-decode'

const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const [user, setUserState] = useState(JSON.parse(localStorage.getItem('user')) || null)
  const [token, setTokenState] = useState(localStorage.getItem('token') || null)
  const [userID, setUserID] = useState(null)
  const [firstname, setFirstName] = useState(null)
  const [lastname, setLastName] = useState(null)
  const [phonenumber, setPhoneNumber] = useState(null)
  const [email, setEmail] = useState(null)
  const [avatar, setAvatar] = useState(null)
  const [role, setRole] = useState(null)
  const [orderUserID, setOrderUserID] = useState(localStorage.getItem('order_id') || null)

  if (token !== null) {
    const decodedToken = jwtDecode(token);

    fetch(`${process.env.REACT_APP_API_URL}/user/${decodedToken.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setUserID(decodedToken.id)
        setFirstName(result.firstname)
        setLastName(result.lastname)
        setPhoneNumber(result.phonenumber)
        setEmail(result.email)
        setAvatar(result.avatar)
        setRole(result.roles)
      })
  }

  const setUser = (newUser) => {
    // localStorage.setItem('user', JSON.stringify(newUser))
    setUserState(newUser)
  }

  const setToken = (newToken) => {
    localStorage.setItem('token', newToken)
    setTokenState(newToken)
  }

  const login = (userData, tokenData) => {
    setUser(userData)
    setToken(tokenData)

    navigate('/')
  }

  const logout = () => {
    // localStorage.removeItem('user')
    localStorage.removeItem('token')
    setUserState(null)
    setTokenState(null)
  }

  return (
    <AuthContext.Provider value={{ user, setUser, token, setToken, login, logout, firstname, setFirstName, lastname, setLastName, phonenumber, setPhoneNumber, email, setEmail, avatar, setAvatar, userID, setUserID, role, setRole, orderUserID, setOrderUserID }}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useAuth = () => useContext(AuthContext)
