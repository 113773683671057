/* eslint-disable prettier/prettier */
import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilCart, cilChartPie, cilCog, cilImage, cilLaptop, cilLibrary, cilList, cilSpeedometer, cilUser } from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react-pro'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Manage Admins',
    to: '/admins',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Manage Advertisement',
    to: '/advertisements',
    icon: <CIcon icon={cilImage} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Manage Coupons',
    to: '/coupons',
    icon: <CIcon icon={cilLibrary} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Manage Customers',
    to: '/customers',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: 'Manage Categories',
    to: '/manage-categories',
    icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Add Categories',
        to: '/categories',
      },
      {
        component: CNavItem,
        name: 'Add Sub Categories',
        to: '/sub-categories',
      },
    ],
  },
  {
    component: CNavItem,
    name: 'Products',
    to: '/products',
    icon: <CIcon icon={cilCart} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: 'Manage Orders',
    icon: <CIcon icon={cilList} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Pending Orders',
        to: '/orders',
      },
      {
        component: CNavItem,
        name: 'Order History',
        to: '/order_history',
      }
    ],
  },
  {
    component: CNavItem,
    name: 'System Settings',
    to: '/system',
    icon: <CIcon icon={cilLaptop} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Profile Settings',
    to: '/profile',
    icon: <CIcon icon={cilCog} customClassName="nav-icon" />,
  },
]

export default _nav
