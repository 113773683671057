import React from 'react'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Users = React.lazy(() => import('./views/customers/index'))
const Categories = React.lazy(() => import('./views/manage-categories/categories/index'))
const SubCategories = React.lazy(() => import('./views/manage-categories/sub-categories/index'))
const Orders = React.lazy(() => import('./views/orders/index'))
const Products = React.lazy(() => import('./views/products/index'))
const Profile = React.lazy(() => import('./views/profile/index'))
const Admins = React.lazy(() => import('./views/admins'))
const Advertisements = React.lazy(() => import('./views/advertisements/index'))
const Coupons = React.lazy(() => import('./views/coupons/index'))
const Details = React.lazy(() => import('./views/orders/detail'))
const OrderHistory = React.lazy(() => import('./views/orders/order_history'))
const SystemSettings = React.lazy(() => import('./views/system/index'))

const routes = [
  { path: '/', name: 'Dashboard', element: Dashboard },
  { path: '/customers', name: 'Customers', element: Users },
  { path: '/advertisements', name: 'Advertisements', element: Advertisements },
  { path: '/coupons', name: 'Coupons', element: Coupons },
  { path: '/categories', name: 'Add Categories', element: Categories },
  { path: '/sub-categories', name: 'Add Sub Categories', element: SubCategories },
  { path: '/orders', name: 'Pending Orders', element: Orders },
  { path: '/detail', name: 'Customer Order Detail', element: Details },
  { path: '/order_history', name: 'Order History', element: OrderHistory },
  { path: '/products', name: 'Products', element: Products },
  { path: '/profile', name: 'Profile', element: Profile },
  { path: '/system', name: 'System Settings', element: SystemSettings },
  { path: '/admins', name: 'Admins', element: Admins },
]

export default routes
