import React, { Component, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider } from './context/AuthContext'
import ProtectedRoute from './components/ProtectedRoute'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" style={{ color: 'rgb(15, 153, 7)' }}></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/auth/login'))
const Register = React.lazy(() => import('./views/auth/register'))
const Page404 = React.lazy(() => import('./views/page404/Page404'))
const Page500 = React.lazy(() => import('./views/page500/Page500'))

class App extends Component {
  render() {
    return (
      <Router>
        <AuthProvider>
          <Suspense fallback={loading}>
            <Routes>
              <Route path="/login" name="Login Page" element={<Login />} />
              <Route path="/register" name="Register Page" element={<Register />} />
              <Route path="/404" name="Page 404" element={<Page404 />} />
              <Route path="/500" name="Page 500" element={<Page500 />} />
              <Route path="*" name="Home" element={<ProtectedRoute component={DefaultLayout} />} />
            </Routes>
          </Suspense>
        </AuthProvider>
      </Router>
    )
  }
}

export default App
