/* eslint-disable prettier/prettier */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CContainer, CHeader, CHeaderBrand, CHeaderNav, CHeaderToggler } from '@coreui/react-pro'
import { AppHeaderDropdown } from './header/index'
import { useAuth } from 'src/context/AuthContext'
import { jwtDecode } from 'jwt-decode'
import CIcon from '@coreui/icons-react'
import { cilMenu } from '@coreui/icons'

const AppHeader = () => {
  const theme = useSelector((state) => state.theme)
  const { firstname, lastname } = useAuth()
  theme === 'dark'
    ? document.body.classList.add('dark-theme')
    : document.body.classList.remove('dark-theme')

  const sidebarShow = useSelector((state) => state.sidebarShow)
  const asideShow = useSelector((state) => state.asideShow)

  const dispatch = useDispatch()
  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
          style={{ marginInlineStart: '-14px' }}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderNav className="ms-auto">
          {firstname && lastname ? (<p className="mt-2">{firstname + ' ' + lastname}</p>) : (<p>Loading name .....</p>)}
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
