import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from './../context/AuthContext'
import DefaultLayout from 'src/layout/DefaultLayout'

const ProtectedRoute = () => {
  const { token, logout } = useAuth()
  // const [isTokenValid, setIsTokenValid] = useState(false)
  // const [isLoading, setIsLoading] = useState(true)

  return token ? <DefaultLayout /> : <Navigate to="/login" replace />
}

export default ProtectedRoute
