/* eslint-disable prettier/prettier */
import React from 'react'
import { CFooter } from '@coreui/react-pro'

const AppFooter = () => {
  return (
    <CFooter>
      <div>
        <a href={process.env.REACT_APP_ABETELECTRONICS} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#3B754A' }}>
          Abet Electronics
        </a>
        <span className="ms-1">&copy; 2023 admin dashboard.</span>
      </div>
      <div className="ms-auto">
        <span className="me-1">Powered by</span>
        <a href={process.env.REACT_APP_SOLVEITSOLUTIONS} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#3B754A' }}>
          SolveIT Solutions
        </a>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
